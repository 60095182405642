import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import lottie from "lottie-web"

import Layout from "../components/layout"
import Seo from "../components/seo"

import animationCat from "../../content/assets/lottie/animation-cat-table.json"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  // *********** LOTTIE CAT ***********
  let lottieCatContainerRef = useRef<HTMLInputElement | null>(null)
  let catAnimRef: any = useRef(null)
  useEffect(() => {
    if (!catAnimRef.current && lottieCatContainerRef.current !== null && lottieCatContainerRef.current) {
      catAnimRef.current = lottie.loadAnimation({
        container: lottieCatContainerRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationCat,
      })
    }
    return () => {
      // catAnimRef.current?.destroy()
    }
  }, [])

  return (
    <Layout
      location={location}
      title={siteTitle}
    >
      <Seo title="404: Not Found" />
      <section className="max-w-screen-lg m-auto  mt-20 justify-items-center align-middle">
        <h1
          className="text-center p-3 mt-5 mb-4 text-xl sm:text-4xl md:text-6xl 
       font-hairline text-white font-family-opensans"
        >
          404
        </h1>
        <p
          className="p-3 mb-4 text-lg md:text-base text-white
        text-center font-family-opensans"
        >
          Page not found or has been moved.
        </p>
        <div
          id="lottie-cat"
          className={`
          w-full
              pb-10 md:pb-40
              overflow-hidden
             flex justify-items-end`}
        >
          <div
            ref={lottieCatContainerRef}
            className={`
            w-full md:w-1/2
            ml-48 md:ml-64 
            origin-right
            transform translate-x-12 md:translate-x-64
            `}
          ></div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
